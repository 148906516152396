
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';

    import HereMap from '@/components/HereMap.vue';

    import {
        IOrganization,
    } from '@/types';

    import {
        addressHelper,
    } from '@/helpers';

    @Component<OrganizationInfosCard>({
        components: {
            HereMap,
        },
    })
    export default class OrganizationInfosCard extends Vue {
        public showMap: boolean =  false;

        @Prop({
            type: Object,
            required: false,
        })
        public organization!: IOrganization;

        @Prop({
            type: Boolean,
            required: false,
            default: false,
        })
        public withLink!: boolean;

        get organizationAddress() {
            if (!this.organization) {
                return null;
            }

            return addressHelper.getHtmlAddress(this.organization);
        }

        get payments() {
            if (!this.organization) {
                return [];
            }

            if (!this.organization.payments) {
                return [];
            }

            return (this.organization.payments as string[]).map((payment) => {
                switch (payment) {
                    case 'american_express': return 'American Express';
                    case 'blue_card': return 'CB';
                    case 'cash': return 'Espèces';
                    case 'check': return 'Chèque';
                    default: return '';
                }
            });
        }
    }
