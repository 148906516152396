import { render, staticRenderFns } from "./OrganizationInfosCard.vue?vue&type=template&id=98a6a098&scoped=true&"
import script from "./OrganizationInfosCard.vue?vue&type=script&lang=ts&"
export * from "./OrganizationInfosCard.vue?vue&type=script&lang=ts&"
import style0 from "./OrganizationInfosCard.vue?vue&type=style&index=0&id=98a6a098&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.8.1_vue-template-compiler@2.7.16_webpack@5.94.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98a6a098",
  null
  
)

export default component.exports