
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {namespace} from 'vuex-class';
    import Plausible from 'plausible-tracker';
    import {
        addressHelper,
    } from '@/helpers';

    import {
        TuvcomModule,
        AnimalModule,
    } from '@/store/modules';

    import {
        IBooking,
        IAgenda,
        IVeterinarian,
        ICommentAndFile,
    } from '@/types';

    import Appointment from '@/components/tuvcom/Appointment.vue';
    import OrganizationInfosCard from '@/components/OrganizationInfosCard.vue';
    import OrganizationLinksCard from '@/components/organization/OrganizationLinksCard.vue';
    import OrganizationNewsCard from '@/components/OrganizationNewsCard.vue';
    import OrganizationPresentationCard from '@/components/OrganizationPresentationCard.vue';
    import VeterinarianEducationCard from '@/components/VeterinarianEducationCard.vue';
    import VeterinarianPresentationCard from '@/components/VeterinarianPresentationCard.vue';
    import HereMap from '@/components/HereMap.vue';

    const tuvcomNamespace = namespace('tuvcom');

    @Component<ViewTuvcom>({
        components: {
            Appointment,
            OrganizationInfosCard,
            HereMap,
            OrganizationLinksCard,
            OrganizationNewsCard,
            OrganizationPresentationCard,
            VeterinarianEducationCard,
            VeterinarianPresentationCard,
        },
    })
    export default class ViewTuvcom extends Vue {
        public isLoading: boolean = false;
        public booking: IBooking|null = null;
        public veterinarian: IVeterinarian|null = null;
        public openDialog: boolean = false;
        public showMap: boolean =  false;
        public imageCarouselIsOpen: boolean = false;
        public commentDialog: boolean = false;

        @tuvcomNamespace.Getter('commentsAndFilesList')
        public tuvcomCommentsAndFiles!: ICommentAndFile[];

        get agenda() {
            if (!this.booking) {
                return null;
            }

            if (!this.veterinarian?.agendas) {
                return null;
            }

            return (this.veterinarian.agendas as IAgenda[]).find((agenda: IAgenda) => {
                return agenda.id === this.booking?.agenda.id;
            });
        }

        get organizationAddress() {
            if (!this.agenda?.organization) {
                return null;
            }

            return addressHelper.getHtmlAddress(this.agenda.organization);
        }

        get payments() {
            if (!this.agenda?.organization) {
                return [];
            }

            if (!this.agenda.organization.payments) {
                return [];
            }

            return (this.agenda.organization.payments as string[]).map((payment) => {
                switch (payment) {
                    case 'american_express': return 'American Express';
                    case 'blue_card': return 'CB';
                    case 'cash': return 'Espèces';
                    case 'check': return 'Chèque';
                    default: return '';
                }
            });
        }

        public handleTrackEvent() {
            const { trackEvent } = Plausible();
            const url = `https://www.chronovet.fr/?id_numcv=${this.agenda?.organization?.social_links?.chronovet_id}&utm_source=banner&utm_medium=cta&utm_campaign=ope_vetolib`;

            trackEvent('chronovetBanner', {
                props: { method: 'HTTP', domain: 'vetolib.fr'},
            });

            window.open(url, '_blank');
        }

        public mounted() {
            this.isLoading = true;

            getModule(TuvcomModule, this.$store)
                .fetchBooking()
                .then((booking: IBooking) => {
                    this.booking = booking;
                    this.$api.agenda
                        .getById(this.booking.agenda.id as string)
                        .then((veterinarian: IVeterinarian) => {
                            this.veterinarian = veterinarian;
                        })
                    ;
                }).catch(()=> window.location.href = window.location.origin)
            ;
        }
    }
