
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';

    import {
        IVeterinarian,
    } from '@/types';

    @Component<VeterinarianPresentationCard>({
        components: {
        },
    })
    export default class VeterinarianPresentationCard extends Vue {
        public imageCarouselIsOpen: boolean = false;
        @Prop({
            type: Object,
            required: false,
        })
        public veterinarian !: IVeterinarian;
    }
