
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';

    import {
        IOrganization,
    } from '@/types';

    @Component<OrganizationPresentationCard>({
        components: {
        },
    })
    export default class OrganizationPresentationCard extends Vue {
        public showMap: boolean =  false;
        public imageCarouselIsOpen: boolean =  false;

        @Prop({
            type: Object,
            required: false,
        })
        public organization!: IOrganization;

        public getVideoId(youtubeURL: string) {
            const regExp: RegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            const match = youtubeURL.match(regExp);
            return (match && match[7].length === 11) ? match[7] : false;
        }
    }
