
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Model, Prop, PropSync} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {DateTime} from 'luxon';

    import CancelAppointmentDialog from '@/components/dialogs/CancelAppointmentDialog.vue';
    import CommentAppointmentDialog from '@/components/tuvcom/CommentAppointmentDialog.vue';
    import MoveAppointmentDialog from '@/components/dialogs/MoveAppointmentDialog.vue';
    import AppointmentCardFile from '@/components/AppointmentCardFile.vue';


    import {
        IBooking,
        ICommentAndFile,
    } from '@/types';

    import {tuvcomHelper} from '@/helpers';

    interface AppointmentCardState {
        expanded: boolean;
        canGoBack: boolean;
    }

    const bookingNamespace = namespace('booking');
    const tuvcomNamespace = namespace('tuvcom');

    @Component<Appointment>({
        components: {
            CancelAppointmentDialog,
            CommentAppointmentDialog,
            MoveAppointmentDialog,
            AppointmentCardFile,
        },
    })
    export default class Appointment extends Vue {
        public cancelDialog: boolean = false;
        public moveDialog: boolean = false;
        public commentDialog: boolean = false;

        public DATETIME_SHORT = DateTime.DATETIME_SHORT;

        @Model()
        public appointment!: IBooking;

        @Prop({
            type: String,
            required: true,
        })
        public mode!: string;

        @PropSync('state', {
            type: Object,
            default: () => {
                return {
                    expanded: false,
                    canGoBack: false,
                };
            },
        })
        public stateSync!: AppointmentCardState;

        @bookingNamespace.Getter('commentsAndFilesList')
        public bookingCommentsAndFiles!: ICommentAndFile[];

        @tuvcomNamespace.Getter('commentsAndFilesList')
        public tuvcomCommentsAndFiles!: ICommentAndFile[];

        get postBookingInstructionsList() {
            return this.appointment.data.filter((elm: any) => !!elm.post_booking_instruction);
        }

        get commentsAndFilesList() {
            if (tuvcomHelper.isTuvcom(this.$route)) {
                return this.tuvcomCommentsAndFiles;
            }
            return this.bookingCommentsAndFiles;
        }

        get canMoveOrDelete(): boolean {
            return DateTime
                .fromISO(this.appointment.start, {zone: this.appointment.timezone})
                .diffNow()
                .as('hours') > 4
            ;
        }


        get avatarPath() {
            if (this.appointment && this.appointment.agenda && this.appointment.agenda.avatar) {
                return this.appointment.agenda.avatar.path;
            }

            return require('@/assets/veterinarians/default_avatar.png');
        }

        // get cardClasses() {
        //     if (this.$route.name === 'tuvcom') {
        //         return {'appointment-card-tuvcom': true};
        //     }

        //     if (this.mode === 'detail') {
        //         return {'appointment-card-full': true};
        //     }

        //     return {'fill-height': true};
        // }

        get cardProps() {
            if (this.mode === 'detail') {
                return {};
            }

            return {
                to: {
                    name: 'appointment',
                    params: {
                        id: this.appointment.id,
                    },
                },
            };
        }

        get status(): string|null {
            if (!this.appointment) {
                return null;
            }

            switch (this.appointment.status) {
                case 'enabled':
                    const start = DateTime.fromISO(this.appointment.start);
                    const end = DateTime.fromISO(this.appointment.end);
                    const now = DateTime.now();

                    if (now < start) {
                        return 'pending';
                    }

                    if (now < end) {
                        return 'now';
                    }

                    return 'done';

                default:
                    return this.appointment.status;
            }
        }

        get statusIconClass(): string|null {
            if (this.status === 'done') {
                return 'success--text';
            } else if (this.status === 'cancelled_by_client' || this.status === 'cancelled_by_pro') {
                return 'error--text';
            }

            return null;
        }

        get statusTooltip(): string|null {
            if (this.status === 'pending') {
                return 'Rendez-vous plannifié';
            } else if (this.status === 'now') {
                return 'Rendez-vous en cours';
            } else if (this.status === 'done') {
                return 'Rendez-vous effectué';
            } else if (this.status === 'cancelled_by_client') {
                return 'Vous avez annulé ce rendez-vous';
            } else if (this.status === 'cancelled_by_pro') {
                    return 'Votre vétérinaire a annulé ce rendez-vous';
            }

            return 'Non renseigné';
        }

        get date(): string|null {
            if (!this.appointment) {
                return null;
            }

            return DateTime
                .fromISO(this.appointment.start, {zone: this.appointment.timezone})
                .toLocaleString(DateTime.DATE_SHORT)
            ;
        }

        get start(): string|null {
            if (!this.appointment) {
                return null;
            }

            return DateTime
                .fromISO(this.appointment.start, {zone: this.appointment.timezone})
                .toLocaleString(DateTime.TIME_SIMPLE)
            ;
        }

        get end(): string|null {
            if (!this.appointment) {
                return null;
            }

            return DateTime
                .fromISO(this.appointment.end, {zone: this.appointment.timezone})
                .toLocaleString(DateTime.TIME_SIMPLE)
            ;
        }

        public calculateAge(dateOfBirth: string) {
            const currentDate = new Date();
            const dateOfBirthArray = dateOfBirth.split('-');
            const birthYear = parseInt(dateOfBirthArray[0]);
            const birthMonth = parseInt(dateOfBirthArray[1]) - 1;
            const birthDay = parseInt(dateOfBirthArray[2]);

            const ageInYears = currentDate.getFullYear() - birthYear;
            const currentMonth = currentDate.getMonth();
            const currentDay = currentDate.getDate();

            let ageInMonths = currentMonth - birthMonth;
            if (currentDay < birthDay) {
                ageInMonths--;
            }

            if (ageInMonths < 0) {
                ageInMonths += 12;
            }

            return `${ageInYears} an(s) et ${ageInMonths} mois`;
        }

        public backButtonClick() {
            if (this.stateSync.canGoBack) {
                return this.$router.back();
            }

            return this.$router.push({name: 'appointments'});
        }

    }
